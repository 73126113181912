import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

function NftTile({ nft }) {
	const [renderError, setRenderError] = useState(true)


	return <div className={`top-nfts__tile ${renderError ? 'invisible' : ''}`}>
		<div className="top-nfts__image-container">
			<div className="top-nfts__image-wrapper">
				{nft.contentType == 'video' ? <video
					src={nft.nftIcon}
					key={nft.nftIcon}
					className={"top-nfts__image"}
					autoPlay="autoplay"
					controls={'controls'}
					muted="muted"
					loop="loop"
					onLoadStart={() => setRenderError(false)}
					onError={() => {
						setRenderError(true)
					}}
					controlsList="nodownload noplaybackspeed noplaybackrate"
					disablePictureInPicture="disablepictureinpicture"
					playsInline
				/>
					: <img className="top-nfts__image" alt=""
						src={nft.nftIcon}
						onLoad={() => setRenderError(false)}
						onError={() => {
							setRenderError(true)
						}}
					/>
				}
			</div>
			{/* <div className="top-nfts__image-container__view-details-button">
				<ViewDetailsButton onTap={null} />
			</div> */}
		</div>
		<div className="top-nfts__info">
			<div className="top-nfts__info__title" id={nft.name}>{nft.name}
				<Tooltip style={{
					backgroundColor: "rgba(27, 30, 56, 1)",
					opacity: 1,
				}} anchorId={nft.name} place="center"
					variant="dark"
					content={nft.name}
				/>
			</div>
			<div className="top-nfts__info__amount">{nft.nftSales}</div>
		</div>
	</div>

}

export default function TopNFTs({ data }) {
	const { t } = useTranslation();
	if (data.topNfts.length === 0) {
		return
	}
	return (
		<div className="section" key={'nft'}>
			<div className="section__top">
				<div className="section__top__title">{t('topNfts')}</div>
			</div>
			<div className="section__game-list">
				{data.topNfts.map((nft, i) => {
					return <NftTile nft={nft} key={i} />;
				})}
			</div>
		</div>
	);
}
