import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { StorageKeys, sendClickEvent } from '../../constants';
import { useTranslation } from 'react-i18next';
import FeaturedIcon from '../../assets/ic_featured_game.png';
import { onPlayButtonClick } from '../detail_page_header/detail_page_header';
import DescriptionWidget from './description_widget';
import Web3GameIcon from '../../assets/ic_web3_game.png';
import './ripple_effect.scss';

export default function FeaturedSection({ gameList, gamesToTryList, clientBaseConfig, showingTutorial, setTutorialState }) {
	const [highlightedIndex, setHighlightedIndex] = useState(0);
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [gameToTry, setGameToTry] = useState();

	useEffect(() => {
		setTimeout(function () {
			if (localStorage.getItem(StorageKeys.tutorialComplete) != 'complete' && isTryoutGame(gameList[highlightedIndex].gameId)) {
				setTutorialState(true);
			}
		}, 2000);
	});

	const isTryoutGame = (gameId) => gamesToTryList.map(game => game.gameId).includes(gameId);

	useEffect(() => {
		if (!showingTutorial) {
			const intervalRef = setInterval(() => {
				setHighlightedIndex(highlightedIndex === gameList.length - 1 ? 0 : highlightedIndex + 1);
			}, 5000);

			return () => {
				clearInterval(intervalRef);
			};
		}
	});


	const HighlightedGameActionButton = ({ gameId, provider, gameIcon, gameName }) => {
		let fromGameToTryList = isTryoutGame(gameId);
		return <div
			className="featured__view-details-button"
			onClick={(e) => {
				if (provider) {
					if (fromGameToTryList) {
						localStorage.setItem(StorageKeys.tutorialComplete, 'complete');
					}
					onPlayButtonClick({
						provider: provider,
						gameName: gameName,
						gameIcon: gameIcon,
						sendClickEvent: () => sendClickEvent({
							'package_name': provider.gameId,
							'banner_location': 'click_web3_webapp_featured_games_cta',
							'event_type': 'App_JumpToDetailPage'
						})
					});
				}
				else {
					navigate('/details/' + gameId, { state: { gameId: gameId } });
					sendClickEvent({
						'package_name': gameId,
						'banner_location': 'click_web3_webapp_featured_games',
						'event_type': 'App_JumpToDetailPage'
					});
				}
				if (fromGameToTryList) setTutorialState(false)
				e.stopPropagation();
			}}>
			{
				showingTutorial ? <div className="ripples">
					<div className="ripple1"></div>
				</div> : null
			}
			{fromGameToTryList ? t('web3TryNow', t('playNow')) : provider ? t('playNow') : t('viewDetails')}
		</div>
	}

	useEffect(() => {
		if ([...gamesToTryList].map((item) => item.gameId).includes(gameList[highlightedIndex].gameId)) {
			setGameToTry(gameList[highlightedIndex]);
		}
	}, [gameList, gamesToTryList, highlightedIndex])

	return (
		<div className="featured">
			<div
				className={`featured__focussed-image ${isTryoutGame(gameList[highlightedIndex].gameId) || gameList[highlightedIndex].provider ? 'cursor-pointer' : ''}`}
				style={{
					backgroundImage: `url(${gameList[highlightedIndex].rotationBanner})`,
				}}
				onClick={() => {
					if (isTryoutGame(gameList[highlightedIndex].gameId)) {
						localStorage.setItem(StorageKeys.tutorialComplete, 'complete');
						navigate('/details/' + gameList[highlightedIndex].gameId, { state: { gameId: gameList[highlightedIndex].gameId } });
						sendClickEvent({
							'package_name': gamesToTryList.filter(g => g.gameId == gameList[highlightedIndex].gameId)[0].provider.gameId,
							'banner_location': 'click_web3_webapp_featured_games',
							'event_type': 'App_JumpToDetailPage'
						});
					}
					if (gameList[highlightedIndex].provider) {
						navigate('/details/' + gameList[highlightedIndex].gameId, { state: { gameId: gameList[highlightedIndex].gameId } });
						sendClickEvent({
							'package_name': gameList[highlightedIndex].gameId,
							'banner_location': 'click_web3_webapp_featured_games',
							'event_type': 'App_JumpToDetailPage'
						});
					}
				}}>
				{isTryoutGame(gameList[highlightedIndex].gameId) ? <div className='featured-section-overlay' /> : <div />}
				<div className='view-details-cta'>
					<div className='first-web3-game'>
						<DescriptionWidget iconUrl={Web3GameIcon} title={t('w3tryGameTitle')} subtitle={t('w3tryGameSummary')} isTryoutGame={isTryoutGame(gameList[highlightedIndex].gameId)} />
					</div>
					{
						gameToTry && isTryoutGame(gameList[highlightedIndex].gameId) ?
							<HighlightedGameActionButton
								provider={gamesToTryList.filter((item) => item.gameId === gameToTry.gameId)[0].provider}
								gameId={gameToTry.gameId}
								gameName={gameToTry.gameName}
								gameIcon={gameToTry.gameIcon} />
							: gameList[highlightedIndex].provider ?
								<HighlightedGameActionButton
									gameId={gameList[highlightedIndex].gameId}
									provider={gameList[highlightedIndex].provider}
									gameName={gameList[highlightedIndex].gameName}
									gameIcon={gameList[highlightedIndex].gameIcon}
								/>
								: <HighlightedGameActionButton
									gameId={gameList[highlightedIndex].gameId}
								/>
					}
				</div>
				<img className='featured-icon' src={FeaturedIcon} />
			</div>
			<div className="featured__carousel">
				{gameList.map((game, i) => {
					let isHighlighted = i === highlightedIndex;
					return (
						<div
							key={i}
							className={`featured__carousel__tile ${isHighlighted ? 'featured__carousel__tile--highlighted ' : ''
								}`}
							onClick={() => {
								setHighlightedIndex(i);
							}}
						>
							<img
								className="featured__carousel__tile__img"
								src={game.gameIcon}
								alt=""
								height="48px"
								width="48px"
							/>
							<div className="featured__carousel__tile__details">
								<span className="featured__carousel__tile__details__name">
									{game.gameName}
								</span>
								{/* <span className="featured__carousel__tile__details__studio">
									{game.developerName}
								</span> */}
							</div>
							<div className="bg-anim"></div>
						</div>
					);
				})}
			</div>
		</div>
	);
}
