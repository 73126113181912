import React, { useEffect } from 'react';
import Rating from '../star_rating/star_rating';
import SingleTokenTagPill from '../single_token_tag_pill/single_token_tag_pill';
import playNowIcon from '../../assets/ic_play_now.png';
import { qwebApi } from '../../qt/qwebApi';
import MultipleTokenTagPill from '../multiple_token_tag_pill/multiple_token_tag_pill';
import { Common } from '../../common';
import ReadMore from '../read_more/read_more';
import { sendClickEvent } from '../../constants';
import { useTranslation } from 'react-i18next';
import './left_right_animation.scss';
import GettingStartedWithWeb3 from '../game_detail_page/components/get_started_with_web3';

const monetaryStatsColors = [
	'rgba(255, 39, 195, 0.3)',
	'rgba(134, 39, 255, 0.3)',
	'rgba(255, 208, 39, 0.3)',
	'rgba(105, 208, 150, 0.3)',
];


let onPlayButtonClick = ({ provider, gameName, gameIcon, sendClickEvent }) => {
	const isCDNPlatform = () => {
		qwebApi({
			event: 'installOrPlayAppforVm',
			data: {
				package_name: provider.gameId,
				apk_url: provider.apkUrl,
				icon_url: gameIcon,
				game_name: gameName,
				action: 'InstallCDN',
			},
			ga: {
				banner_location: "web3-webapp"
			}
		});
	}
	const isGooglePlatform = () => {
		qwebApi({
			event: 'installOrPlayAppforVm',
			data: {
				package_name: provider.gameId,
				action: 'InstallPlay',
			},
			ga: {
				banner_location: "web3-webapp"
			}
		});
	}

	if (provider.type === "google_play") {
		isGooglePlatform();
	}
	else if (provider.type === "cdn") {
		isCDNPlatform();
	}
	sendClickEvent();
}

function PlayNowButton({ onClick }) {
	const { t } = useTranslation();
	return (
		<div
			className="play-now-button"
			onClick={onClick}
		>
			<img className="play-icon" alt="" src={playNowIcon}></img>
			<div>{t('playNow')}</div>
		</div>
	);
}

function MonetaryStat({ stat, index }) {

	useEffect(() => {
		let container = document.querySelector(`#stat-${index}`);
		if (container.clientWidth > 130) container.classList.add("animate");
		else container.classList.remove("animate");
	}, [])

	const { t } = useTranslation();
	const getFooter = () => {
		if (stat.footer) {
			return <div className="monetary-stat__footer">{stat.footer}</div>;
		}
	};
	const containerStyle = {
		backgroundImage: `linear-gradient(to bottom,  rgba(0, 0, 0, 0.3) , ${monetaryStatsColors[index]})`,
	};
	const getValue = () => {
		const val = Common.abbreviateNumber(stat.value)
		if (val) {
			return <div className="monetary-stat__value">{val}</div>
		}
		return <div className="monetary-stat__value undefined">{t('unavailable')}</div>
	}

	return (
		<div className="monetary-stat" style={containerStyle}>
			<div id={`stat-${index}`} className={`monetary-stat__title`}><span>{stat.title.toUpperCase()}</span></div>
			{getValue()}
			{getFooter()}
		</div>
	);
}

function PlayButtonStatsCategory({ data }) {
	const { isFeatured = false, categories = [], gameId, isTryGame = false } = data;
	const { t } = useTranslation();
	if (isFeatured) {
		categories.unshift(t('featured'))
	}
	const validStats = data.monetaryStat.filter((stat) => stat.value != null && stat.value != undefined && stat.value != 0)
	return (
		<div className="detail-page__play-button-stats-categories">
			<div className='detail-page__game-categories'>
				{data.categories.map((category, index) => {
					return <div key={index} className='game-category-pill'>{category}</div>
				})}
			</div>
			{isTryGame ? <GettingStartedWithWeb3 /> : validStats.length > 0 ? <div className="detail-page__stats">
				{data.monetaryStat.map((stat, i) => {
					return <MonetaryStat stat={stat} index={i} key={i} />;
				})}
			</div> : ''}
			<PlayNowButton onClick={() => onPlayButtonClick({
				provider: data.provider, gameName: data.gameName, gameIcon: data.icon,
				sendClickEvent: () => sendClickEvent({
					'package_name': data.provider.gameId,
					'banner_location': 'click_web3_webapp_game_details_cta',
					'event_type': 'App_JumpToDetailPage'
				})
			})} />
		</div>
	);
}

function GameDeveloperAndRatings({ data }) {
	return (
		<div className="rating-developer-info">
			<Rating value={parseFloat(data.ratings.value)} maxValue={parseFloat(data.ratings.maxValue)} />
			<div className="developer-info">
				<div>{data.developerName}</div>
			</div>
		</div>
	);
}

function BlockchainInfo(tokenList) {
	const { t } = useTranslation();
	if (tokenList.length === 0) {
		return
	}
	if (tokenList.length === 1) {
		const blockchain = tokenList[0]
		return <div className="token-info">
			<SingleTokenTagPill
				token={{
					icon: blockchain.icon,
					name: blockchain.name,
					type: t('blockchain').toUpperCase(),
				}}
			/>
		</div>
	}
	return (
		<div className="token-info">
			<MultipleTokenTagPill tokenList={tokenList} tokenType={t('blockchain').toUpperCase()} />
		</div>
	);
}

function TokenInfo(tokenList) {
	const { t } = useTranslation();
	if (tokenList.length === 0) {
		return
	}
	if (tokenList.length === 1) {
		const token = tokenList[0]
		return <div className="token-info">
			<SingleTokenTagPill
				token={{
					icon: token.icon,
					name: token.name,
					type: t('token').toUpperCase(),
				}}
			/>
		</div>
	}
	return (
		<div className="token-info">
			<MultipleTokenTagPill tokenList={tokenList} tokenType={t('token').toUpperCase()} />
		</div>
	);
}

function CoinInfo({ data }) {
	return <div className="token-info-wrapper">
		{BlockchainInfo(data.blockchains)}
		{TokenInfo(data.tokens)}
	</div>
}

function GameInfo({ data }) {
	return (
		<div className="detail-page__game-info">
			<img
				className="detail-page__game-info__title-image"
				src={data.logo}
			></img>
			<div className="detail-page__game-info__row">
				{/* {getFeaturedIcon(data.isFeatured)} */}
				<GameDeveloperAndRatings data={data} />
				<CoinInfo data={data} />
			</div>
			<div className="detail-page__game-info__description">
				<ReadMore gameDetailData={data} />
			</div>
		</div>
	);
}

function DetailPageHeader({ data }) {
	return (
		<div className='detail-page__header-section-wrapper'>
			<div className='detail-page__header-top-section'></div>
			<div className="detail-page__header-section">
				<GameInfo data={data} />
				<PlayButtonStatsCategory data={data} />
			</div>
			<div className='detail-page__header-bottom-section'></div>
		</div>

	);
}

export default DetailPageHeader;
export { onPlayButtonClick };
