import React from 'react';
import CircularTokenImage from '../circular_token_image/circular_token_image';
import ViewDetailsButton from '../view_details_button/view_details_button';
import { useNavigate } from 'react-router-dom';
import { gameTileSourceMapping, sendClickEvent } from '../../constants';

function GameTile({ game, genreSectionType, source }) {
	const navigate = useNavigate();

	const gameTileStyle = {
		backgroundImage: `url(${game.gameCard})`,
	};
	const gameTitleStyle = {
		backgroundImage: `url(${game.gameLogo})`,
	};
	const tokenList = [...game.blockchains, ...game.tokens];

	const getBannerLocationValue = () => {
		if (genreSectionType) {
			return gameTileSourceMapping['genregamesclick'] + genreSectionType;
		}
		else if (source) {
			return gameTileSourceMapping[source];
		}
		return 'unexpected_source';
	}

	const getOnTap = (game) => {
		return () => {
			navigate('/details/' + game.gameId, { state: { gameId: game.gameId } });
			sendClickEvent({
				'package_name': game.appPackage ?? game.gameId,
				'banner_location': getBannerLocationValue(),
				'event_type': 'App_JumpToDetailPage'
			})
		}
	}


	return (
		<div className="game-tile game-tile--clickable" onClick={getOnTap(game)} style={gameTileStyle}>
			<div className="game-tile__token_images">
				{tokenList.map((token, index) => {
					return <CircularTokenImage token={token} key={index} />
				})}
			</div>
			<div className="game-tile__logo" style={gameTitleStyle}></div>
			<div className="game_tile__backdrop"></div>
			<div className="game-tile__overlay__view-detail-button">
				<ViewDetailsButton />
			</div>
		</div>
	);
}

export default GameTile;
