import { useEffect, useState } from 'react';
import './description_widget.scss';

const DescriptionWidget = ({ iconUrl, title, subtitle, isTryoutGame }) => {

    const [dimensions, setSize] = useState([window.innerWidth, window.innerHeight]); // To maintain state of window dimensions

    function resize() {
        setSize([window.innerWidth, window.innerHeight]);
    }

    useEffect(() => {
        window.addEventListener('resize', resize);
        return () => window.removeEventListener('resize', resize);
    }, [])

    const titleContainer = document.querySelector(`.title`);
    const subTitleContainer = document.querySelector(`.sub-title`);
    const focusedImageContainer = document.querySelector(`.featured__focussed-image`);
    useEffect(() => {
        if (titleContainer && subTitleContainer && focusedImageContainer) {
            if (titleContainer.clientWidth > focusedImageContainer.clientWidth - 300) titleContainer.classList.add("animate");
            else titleContainer.classList.remove("animate");
        }
    }, [titleContainer, subTitleContainer, dimensions, focusedImageContainer, title, subtitle])

    return <div className={`description-widget ${isTryoutGame ? '' : 'invisible'}`}>
        <img className="icon" src={iconUrl} />
        <div className="details">
            <div className="title">{title}</div>
            <div className="sub-title">{subtitle}</div>
        </div>
    </div>
}

export default DescriptionWidget;