import React from 'react';
import CircularTokenImage from '../circular_token_image/circular_token_image.js';
import { Tooltip } from 'react-tooltip'

const getTokenName = (s) => {
    const ret = s;
    return ret.toUpperCase()
}

export default function SingleTokenTagPill({ token }) {
    return <div className='tag-pill-token'>
        <div className='tag-pill-token-type'>{token.type}</div>
        <div className='tag-pill' id={getTokenName(token.name)}>
            <CircularTokenImage token={token} />
            <div className='tag-pill__title'>{token.name}
            <Tooltip style={{
                backgroundColor: "rgba(27, 30, 56, 1)",
                opacity: 1,
            }} anchorId={getTokenName(token.name)} place="top"
                variant="dark"
                content={getTokenName(token.name)}
            />
            </div>
        </div>
    </div>
}