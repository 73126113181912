import React, { useEffect } from 'react';
import enabledBtn from '../../assets/ic_forward_enabled.png'
import disabledBtn from '../../assets/ic_forward_disabled.png'

function EnabledButton() {
    return <img className='scroll-btn' src={enabledBtn}></img>
}

function DisabledButton() {
    return <img className='scroll-btn' src={disabledBtn}></img>
}

export default function ScrollButtons({ scrollableContainerId }) {
    let [scrollOffset, setScrollOffset] = React.useState(0)
    let [scrollButtonVisible, setScrollButtonVisible] = React.useState(false);

    const scrollWidthEqualToBoundingClientRect = (container, scrollOffset) => {
        return Math.floor(scrollOffset + container.getBoundingClientRect().width) === Math.floor(container.scrollWidth)
    }

    const hideShowButtons = () => {
        const scrollableContainer = document.getElementById(scrollableContainerId)
        if (scrollableContainer && scrollOffset == 0 && scrollWidthEqualToBoundingClientRect(scrollableContainer, scrollOffset)) {
            setScrollButtonVisible(false);
        }
        else {
            setScrollButtonVisible(true);
        }
    }

    useEffect(() => {
        window.addEventListener('resize', hideShowButtons);
        return () => {
            window.removeEventListener('resize', hideShowButtons)
        }
    }, [hideShowButtons])

    const scrollLength = document.body.offsetWidth;

    const updateScrollOffset = () => {
        setTimeout(() => {
            setScrollOffset(document.getElementById(scrollableContainerId).scrollLeft)
        }, 0)
    }

    const scrollBackward = () => {
        document.getElementById(scrollableContainerId).scrollBy({ left: -scrollLength, behavior: 'smooth' })
        updateScrollOffset()
    }
    const scrollForward = () => {
        document.getElementById(scrollableContainerId).scrollBy({ left: scrollLength, behavior: 'smooth' })
        updateScrollOffset()
    }

    const forwardBtn = () => {
        const scrollableContainer = document.getElementById(scrollableContainerId)
        if (scrollableContainer == null) {
            return <EnabledButton />
        }
        if (scrollWidthEqualToBoundingClientRect(scrollableContainer, scrollOffset)) {
            return <DisabledButton />
        }
        return <EnabledButton />
    }

    const backwardBtn = () => {
        if (scrollOffset == 0) {
            return <DisabledButton />
        }
        else {
            return <EnabledButton />
        }
    }

    const getScrollButtons = () => {
        if (scrollButtonVisible) {
            return <div className='scroll-buttons'>
                <div className='scroll-button-backward' onClick={scrollBackward}>{backwardBtn()}</div>
                <div className='scroll-button-froward' onClick={scrollForward}>{forwardBtn()}</div>
            </div>
        }
    }

    const scrollableContainer = document.getElementById(scrollableContainerId)

    useEffect(() => {
        setTimeout(() => {

            if (scrollableContainer && scrollOffset == 0 && scrollWidthEqualToBoundingClientRect(scrollableContainer, scrollOffset)) {
                setScrollButtonVisible(false);
            }
            else {
                setScrollButtonVisible(true);
            }
        }, 800);
    }, [scrollableContainer])

    return getScrollButtons()
}