import { NetworkManager } from "../network_manager/network_manager";
import { APIS } from "../network_manager/api_constants";
import { BSX_VERSION } from "../constants";
import { fetchQwebApi } from "../qt/qwebApi";
import { Common } from "../common";

function fetchGameListing() {
    return NetworkManager.get({
        absoluteApi: APIS.gameListing + Common.getLanguage(),
        apiName: "Game Listing",
        queryParams: {
            method: 'POST',
            headers: {
                'Authorization': process.env.REACT_APP_WEB3_AUTH_KEY
            }
        },
        returnableObjectCallback: (response) => {
            const getListSectionData = (obj) => {
                return obj.map((data) => {
                    return {
                        blockchains: data.blockchains.map((b) => {
                            return {
                                name: b.name,
                                icon: b.icon,
                            }
                        }),
                        tokens: data.tokens.map((t) => {
                            return {
                                name: t.symbol,
                                icon: t.icon,
                            }
                        }),
                        gameLogo: data.game_logo,
                        gameCard: data.game_card,
                        gameId: data.game_id,
                    }
                })
            }

            const getEditorChoiceData = (obj) => {
                return obj.map((data) => {
                    const provider = data?.provider
                    return {
                        developerName: data.developer_name,
                        gameIcon: data.game_icon,
                        gameId: data.game_id,
                        gameName: data.game_name,
                        rotationBanner: data.rotation_banner,
                        provider: provider ? {
                            type: provider['type'],
                            platform: provider['platform'],
                            gameId: provider['game_id'],
                            apkUrl: provider['apk_url'],
                            appVersionCode: provider['app_version_code']
                        } : undefined
                    }
                })
            }

            const getGamesToTry = (obj) => {
                return obj.map((data) => {
                    const provider = data['provider']
                    return {
                        gameId: data['game_id'],
                        provider: {
                            type: provider['type'],
                            platform: provider['platform'],
                            gameId: provider['game_id'],
                            apkUrl: provider['apk_url'],
                            appVersionCode: provider['app_version_code']
                        }
                    }
                })
            }

            const getGenreData = (obj) => {
                return obj;
            }

            const data = response.web3_appcenter_landing_page.collections;
            const editorChoiceData = data["Editors choice"];
            const highestRatedData = data["Highest Rated"];
            const mostNftSalesData = data["Most NFT sales"];
            const mostPopularData = data["Most Popular"];
            const genreData = getGenreData(response.web3_appcenter_landing_page.genres);
            let gameListingObject = {
                EditorChoice: getEditorChoiceData(editorChoiceData),
                HighestRated: getListSectionData(highestRatedData),
                MostNftSales: getListSectionData(mostNftSalesData),
                MostPopular: getListSectionData(mostPopularData),
                GamesToTry: getGamesToTry(response.web3_appcenter_landing_page.games_to_try),
                Genres: genreData,
            };
            // console.log("Game Listing Object:", gameListingObject);
            return gameListingObject;
        },
    })
}

async function fetchGameDetail(gameId) {
    const clientBaseConfig = await fetchClientConfig();
    let config = clientBaseConfig
    if (config === {} || !config) {
        config = {
            x_version: '0.19.1.0',
            launcher_guid: null,
            oem: 'nxt'
        }
    }
    return NetworkManager.get({
        absoluteApi: APIS.gameDetail + Common.getLanguage(),
        apiName: "Game Detail",
        queryParams: {
            method: 'POST',
            headers: {
                'Authorization': process.env.REACT_APP_WEB3_AUTH_KEY,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "filters": [{
                    "key": "game_id",
                    "value": [gameId]
                }],
                "bsx_version": config.x_version,
                "launcher_guid": config.launcher_guid,
                "oem": 'nxt'
            }),
        },
        returnableObjectCallback: (response) => {
            const data = response.web3_appcenter_game_details;
            const getNftListObject = (obj) => {
                return obj.map((data) => {
                    return {
                        name: data.name,
                        nftIcon: data.url,
                        nftSales: data.price,
                        contentType: data.content_type
                    }
                })
            }
            const getGettingStartedObject = (obj) => {
                return obj.map((data) => {
                    return {
                        title: data.title,
                        icon: data.icon,
                    }
                })
            }
            const getMonetaryStatObject = (obj) => {
                return obj.map((data) => {
                    return {
                        footer: data.footer,
                        title: data.title,
                        value: data.title == "volume" ? "$" + data.value : data.value,
                    }
                })
            }
            var gameDetailObject = {
                banner: data.assets.banner,
                logo: data.assets.logo,
                icon: data.assets.icon,
                gameMedia: data.assets.media,
                blockchains: data.blockchains.map(b => {
                    return {
                        icon: b.icon,
                        name: b.name,
                    }
                }),
                tokens: data.fungible_tokens.map(t => {
                    return {
                        icon: t.icon,
                        name: t.symbol,
                    }
                }),
                gameName: data.name,
                developerName: data.developer_name,
                gameId: data.id,
                provider: {
                    apkUrl: data.provider.apk_url,
                    type: data.provider.type,
                    appVersionCode: data.provider.app_version_code,
                    gameId: data.provider.game_id
                },
                ratings: {
                    maxValue: data.ratings.max_value,
                    value: data.ratings.value,
                },
                categories: data.category,
                isFeatured: data.is_featured,
                description: data.description,
                topNfts: getNftListObject(data.top_nfts),
                monetaryStat: getMonetaryStatObject(data.stats),
                gettingStarted: getGettingStartedObject(data.steps_to_get_started),
                isTryGame: data.is_try_game
            }
            // console.log("Game Detail Object:", gameDetailObject);
            return gameDetailObject;
        }
    })
}

function fetchGenreDetails(genre) {
    return NetworkManager.get({
        absoluteApi: APIS.genre + Common.getLanguage(),
        apiName: "Genre Details",
        queryParams: {
            method: 'POST',
            headers: {
                'Authorization': process.env.REACT_APP_WEB3_AUTH_KEY,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "filters": [{
                    "key": "categories",
                    "value": [genre]
                }]
            })
        },
        returnableObjectCallback: (response) => {
            const data = response.web3_appcenter_genre_page;
            const genreType = Object.keys(data)[0]
            const genreDetailObj = {
                genreType: genreType,
                gameList: data[genreType].map((game) => {
                    return {
                        gameLogo: game.game_logo,
                        gameCard: game.game_card,
                        gameId: game.game_id,
                        blockchains: game.blockchains.map(b => {
                            return {
                                icon: b.icon,
                                name: b.name,
                            }
                        }),
                        tokens: game.tokens.map(t => {
                            return {
                                icon: t.icon,
                                name: t.symbol,
                            }
                        }),
                        appPackage: game.app_package
                    }
                })
            }
            // console.log("Genre Detail Object:", genreDetailObj);
            return genreDetailObj;
        }
    })
}

async function fetchClientConfig() {
    return await fetchQwebApi(
        { event: 'getClientConfig' },
        { initial: { x_version: BSX_VERSION } }
    );
}

export { fetchGameDetail, fetchGameListing, fetchGenreDetails, fetchClientConfig }