import React from 'react';
import CircularTokenImage from '../circular_token_image/circular_token_image';
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css';

const getTokenName = (s) => {
    const ret = s;
    return ret.toUpperCase()
}

const TokenItem = ({ token }) => {
    return <div className="single-token-hover" id={getTokenName(token.name)}>
        <CircularTokenImage token={token} />
    </div>
}

export default function MultipleTokenTagPill({ tokenList, tokenType }) {
    if (tokenList.length === 0) {
        return
    }
    return <div className="multiple-token-tag-pill">
        <div className="multiple-token-tag-pill__header">{tokenType}</div>
        <div className="multiple-token-tag-pill__pill">
            {tokenList.map((token, index) => {
                return (<div className="token-with-tooltip" key={index}>
                    <TokenItem token={token} />
                    <Tooltip style={{
                        backgroundColor: "rgba(27, 30, 56, 1)",
                        opacity: 1,
                        fontSize : 12,
                        fontWeight : 500,
                    }} anchorId={getTokenName(token.name)} place="top"
                        variant="dark"
                        content={getTokenName(token.name)}
                    />
                </div>)
            })}
        </div>
    </div>
}