import nftPlaceholderImage from './assets/bg_nft_placeholder.png';
import { qwebApi } from './qt/qwebApi';

class Constants {
    static fallbackTokenString = "NP"
    static nftFallbackImage = nftPlaceholderImage;
    static landingPageBackgroundUrl = 'https://crypto-blockchain-cdn.now.gg/web3-appcenter/listing-page-bg.jpg';
    static overlayImageUrl = 'https://crypto-blockchain-cdn.now.gg/web3-appcenter/coachmark_overlay.png';
}

class StorageKeys {
    static tutorialComplete = 'tutorial_complete';
}

const gameTileSourceMapping = {
    'most-popular': 'click_web3_webapp_most_popular',
    'highest-rated': 'click_web3_webapp_highest_rated',
    'most-nft-sales': 'click_web3_webapp_most_nft_sales',
    'genregamesclick': 'click_web3_webapp_genre_games_',
    'refresh': 'click_web3_webapp_something_went_wrong_refresh'
}

const DialogIds = {
    readMore: 'read_more'
}

const sendClickEvent = (details) => {
    qwebApi({
        event: 'postStats',
        data: {
            table: 'launcher_app_click_stats',
            data: details
        },
    })
}

const BSX_VERSION = '0.16.1.0'
export { Constants, gameTileSourceMapping, sendClickEvent, BSX_VERSION, DialogIds, StorageKeys }